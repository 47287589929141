import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLiveChatAgent, getLiveChatAgentId } from 'services/API';


export const getLiveChatAgentThunk = createAsyncThunk(
  'livechat/chats',
  async ({token, page}, thunkAPI) => {
    try {
      const response = await getLiveChatAgent(token, page);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getLiveChatAgentIdThunk = createAsyncThunk(
  'livechat/agent',
  async ({token, id}, thunkAPI) => {
    try {
      const response = await getLiveChatAgentId(token, id);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);