import {
  AnalyticsBlock,
  AnalyticsItem,
  AnalyticsList,
  AnalyticsMainText,
  CommentText,
  CorrectItem,
  IdInformation,
  MainText,
  Minus,
  MistakeItem,
  PercentBarBlock,
  PercentItem,
  Plus,
  Progress,
  ProgressBar,
  ResultsBlock,
  SupportMenuConteiner,
  TextBlock,
  TextInformationBlock,
  User,
  UserBlock,
  UserGradeBlock,
  UserImg,
  UserJobTitle,
  UserName,
} from './SupportMenu.styled';
import imgTest from '../../../images/userPhoto.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveChatAgentIdThunk } from '../../../redux/qa/qaActions';
import {
  selectAccessToken,
  selectQaChatsAgent,
} from '../../../redux/selectors';

const SupportMenu = ({ userId, chats, calculateFalsePercentage }) => {
  const [openTextIndex, setOpenTextIndex] = useState(null);
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const agent = useSelector(selectQaChatsAgent);

  const currentChat = chats.filter(item => item.pk === userId);
  const trueCount = currentChat[0]?.score?.true;
  const falseCount = currentChat[0]?.score?.false;

  const percentageOfFalse = calculateFalsePercentage(trueCount, falseCount);

  const toggleText = index => {
    setOpenTextIndex(prevIndex => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    dispatch(getLiveChatAgentIdThunk({ token, id: userId }));
  }, [userId]);

  return (
    <SupportMenuConteiner>
      <TextInformationBlock>
        <IdInformation>{currentChat[0]?.thread_id}</IdInformation>
      </TextInformationBlock>
      <UserGradeBlock>
        <User>
          {currentChat[0]?.photo === null ? (
            <UserImg src={imgTest} />
          ) : (
            <UserImg src={currentChat[0]?.photo} />
          )}
          <UserBlock>
            <UserJobTitle>Agent Name</UserJobTitle>
            {currentChat[0]?.username === null ? (
              <UserName>Артём Ковальцев</UserName>
            ) : (
              <UserName>{currentChat[0]?.username}</UserName>
            )}
          </UserBlock>
        </User>
        <PercentBarBlock>
          <PercentItem>Call Score {percentageOfFalse}%</PercentItem>
          <ProgressBar>
            <Progress percent={percentageOfFalse} />
          </ProgressBar>
        </PercentBarBlock>
        <ResultsBlock>
          <MistakeItem>Ошибок: {trueCount}</MistakeItem>
          <CorrectItem>Корректно: {falseCount}</CorrectItem>
        </ResultsBlock>
      </UserGradeBlock>
      <AnalyticsBlock>
        <AnalyticsMainText>Analytics</AnalyticsMainText>
        <AnalyticsList>
          {agent?.results.map((item, index) => (
            <AnalyticsItem onClick={() => toggleText(index)} key={index}>
              <TextBlock>
                {item.question}
                {openTextIndex === index && (
                  <CommentText> {item.explanation}</CommentText>
                )}
              </TextBlock>
              {item.decision === false ? <Plus /> : <Minus />}
            </AnalyticsItem>
          ))}
        </AnalyticsList>
      </AnalyticsBlock>
    </SupportMenuConteiner>
  );
};

export default SupportMenu;
