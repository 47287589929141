import { useState, useRef, useEffect } from 'react';
import {
  ConteinerPaginate,
  DateInfo,
  IdInfo,
  List,
  PaginateButtonLeft,
  PaginateButtonRight,
  PaginateNumber,
  PenButton,
  PercentItem,
  Progress,
  ProgressBar,
  StatInformationBlock,
  StatsPageItem,
  StatsPageListConteiner,
  StatusInfo,
  TimeInfo,
  UserImg,
  UserInfoBlock,
  UserName,
} from './SupportVip.styled';
import testImg from '../../../images/testImg.png';
import SupportMenu from '../SupportMenu/SupportMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveChatAgentThunk } from '../../../redux/qa/qaActions';
import { selectAccessToken, selectQaChats } from '../../../redux/selectors';

const SupportVip = () => {
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [trueCount, setTrueCount] = useState(null);
  const [falseCount, setFalseCount] = useState(null);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [time, setTime] = useState(null);
  const [hours, setHours] = useState(null);
  const [minute, setMinute] = useState(null);
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(1);
  const token = useSelector(selectAccessToken);
  const chats = useSelector(selectQaChats);
  const chatsItem = chats?.results;

  console.log(trueCount)
  console.log(falseCount)

  // console.log(chatsItem);

  useEffect(() => {
    chatsItem?.forEach(item => {
      const timeString = item.chat_duration;

      const [hours, minutes] = timeString.split(':');

      setHours(hours);
      setMinute(minutes);
    });
  }, [chatsItem]);

  useEffect(() => {
    chatsItem?.forEach(item => {
      const dateString = item.created_at;

      const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2})/;
      const match = dateString.match(regex);

      if (match) {
        const year = match[1];
        const month = match[2];
        const day = match[3];
        const time = match[4];

        setDay(day);
        setMonth(month);
        setYear(year);
        setTime(time);
      } else {
        console.log('Неверный формат даты');
      }
    });
  }, [chatsItem]);

  useEffect(() => {
    let totalTrue = 0;
    let totalFalse = 0;

    chatsItem?.forEach(item => {
      totalTrue += item.score.true || 0;
      totalFalse += item.score.false || 0;
    });

    setTrueCount(totalTrue);
    setFalseCount(totalFalse);
  }, [chatsItem]);

  const toggleModal = userId => {
    setOpenModal(prevState => !prevState);
    setUserId(userId);
  };

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  useEffect(() => {
    dispatch(getLiveChatAgentThunk({ token, page }));
  }, [page]);

  function calculateFalsePercentage(trueCount, falseCount) {
    const totalCount = trueCount + falseCount;
    console.log(totalCount)
    if (totalCount === 0) {
      return 0;
    }
    const percentage = (falseCount / totalCount) * 100;
    return Math.round(percentage.toFixed(2));
  }

  // const percentageOfFalse = calculateFalsePercentage(trueCount, falseCount);

  return (
    <>
      <StatsPageListConteiner>
        <List>
          {chatsItem?.map(item => (
            <StatsPageItem key={item.thread_id}>
              <UserInfoBlock>
                {item.photo === null ? (
                  <UserImg src={testImg} />
                ) : (
                  <UserImg src={item.photo} />
                )}
                {item.username === null ? (
                  <UserName>Артём Ковальцев</UserName>
                ) : (
                  <UserName>{item.username}</UserName>
                )}
                <IdInfo>{item.thread_id}</IdInfo>
              </UserInfoBlock>
              <ProgressBar>
                <Progress percent={calculateFalsePercentage(item.score.true, item.score.false)} />
              </ProgressBar>
              <PercentItem>{calculateFalsePercentage(item.score.true, item.score.false)}%</PercentItem>
              <StatInformationBlock>
                {/* <StatusInfo>Завершён</StatusInfo> */}
                <DateInfo>
                  {day}/{month}/{year} | {time}
                </DateInfo>
                <TimeInfo>
                  {hours} час {minute} минуты
                </TimeInfo>
              </StatInformationBlock>
              <PenButton onClick={() => toggleModal(item.pk)} />
            </StatsPageItem>
          ))}
        </List>
        <ConteinerPaginate>
          {chats?.previous === null ? (
            <></>
          ) : (
            <PaginateButtonLeft
              onClick={() => (page < 1 ? setPage(0) : setPage(page - 1))}
            />
          )}
          {page < 1 ? (
            <PaginateNumber>1</PaginateNumber>
          ) : (
            <PaginateNumber>{page}</PaginateNumber>
          )}
          {chats?.next === null ? (
            <></>
          ) : (
            <PaginateButtonRight onClick={() => setPage(page + 1)} />
          )}
        </ConteinerPaginate>
      </StatsPageListConteiner>
      {openModal && (
        <div ref={modalRef}>
          <SupportMenu
            userId={userId}
            chats={chatsItem}
            calculateFalsePercentage={calculateFalsePercentage}
          />
        </div>
      )}
    </>
  );
};

export default SupportVip;
